<template>
  <div class="publish-wrapper">
    <h3>发布职位</h3>
    <el-form ref="form" :model="form" label-width="90px" width="300px">
      <div class="divider">
        <div class="posts">
          <h4>请填写职位描述</h4>
          <el-form-item label="招聘类型：">
            <el-radio-group v-model="form.resource" class="names">
              <el-radio label="社招"></el-radio>
              <el-radio label="校招（只招聘残疾人大学生）"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否急聘：">
            <el-radio-group v-model="form.resource" class="names">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="职位名称：" prop="name">
            <el-input v-model="form.name" placeholder="请输入职位名称" class="names"></el-input>
          </el-form-item>
          <el-form-item label="职位类别：">
            <el-select v-model="form.region" placeholder="请选择职位类别" class="names">
              <el-option label="全职" value="shanghai"></el-option>
              <el-option label="兼职" value="beijing"></el-option>
              <el-option label="实习" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位类型：">
            <el-checkbox-group v-model="form.type">
              <el-checkbox label="全职" name="type"></el-checkbox>
              <el-checkbox label="兼职" name="type"></el-checkbox>
              <el-checkbox label="实习" name="type"></el-checkbox>
              <el-checkbox label="灵活（不需要按时坐班）" name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="职位人数：">
            <el-select v-model="form.region" placeholder="请选择职位人数" class="names">
              <el-option label="若干" value="shanghai"></el-option>
              <el-option label="1-2人" value="beijing"></el-option>
              <el-option label="5-6人" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="招聘时间：">
            <el-col :span="12" class="timing">
              <el-date-picker
                type="date"
                placeholder="开始日期"
                v-model="form.date1"
                style="width: 100%;"
                popper-class="eagle-a11y-uncut"
              ></el-date-picker>
            </el-col>
            <el-col class="line" :span="1">-</el-col>
            <el-col :span="11" class="timing">
              <el-date-picker
                v-model="form.endTime"
                type="date"
                style="width: 100%;"
                placeholder="结束日期"
                popper-class="eagle-a11y-uncut"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="职位描述：">
            <Editor
              placeholder="请输入职位描述"
              class="w305"
              @html="jobDescriptval"
              :testcontent="form.jobDescript"
            ></Editor>
          </el-form-item>
          <el-form-item label="任职要求：">
            <Editor
              placeholder="请输入职位要求"
              class="w305"
              @html="jobRequirementval"
              :testcontent="form.jobRequirement"
            ></Editor>
          </el-form-item>
        </div>
        <div class="works">
          <h4>请填写工作要求</h4>
          <el-form-item label="职位类别：">
            <el-select v-model="form.region" placeholder="请选择职位类别" class="names">
              <el-option label="全职" value="shanghai"></el-option>
              <el-option label="兼职" value="beijing"></el-option>
              <el-option label="实习" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="残疾等级：">
            <el-select v-model="form.region" placeholder="请选择职位类别" class="names">
              <el-option label="全职" value="shanghai"></el-option>
              <el-option label="兼职" value="beijing"></el-option>
              <el-option label="实习" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作经验：">
            <el-select v-model="form.region" placeholder="请选择职位类别" class="names">
              <el-option label="全职" value="shanghai"></el-option>
              <el-option label="兼职" value="beijing"></el-option>
              <el-option label="实习" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历要求：">
            <el-select v-model="form.region" placeholder="请选择职位人数" class="names">
              <el-option label="若干" value="shanghai"></el-option>
              <el-option label="1-2人" value="beijing"></el-option>
              <el-option label="5-6人" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="月薪范围：">
            <el-col :span="11" class="timing">
              <el-date-picker
                type="date"
                placeholder="开始日期"
                v-model="form.date1"
                style="width: 100%;"
                popper-class="eagle-a11y-uncut"
              ></el-date-picker>
            </el-col>
            <el-col class="line" :span="1">-</el-col>
            <el-col :span="11" class="timing">
              <el-date-picker
                v-model="form.endTime"
                type="date"
                style="width: 100%;"
                placeholder="结束日期"
                popper-class="eagle-a11y-uncut"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item>
            <span class="checkbox">只能输入整数 如“9”</span>
            <el-checkbox v-model="checked" label="面议" class="facing"></el-checkbox>
          </el-form-item>
          <el-form-item label="工作地点：">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="tags">
      <h4>职位标签</h4>
      <p>从以下标签中选择</p>
      <small class="color99 marL10">（最多可选择5个标签，不允许重复）</small>

      <div class="form">
        <el-input
          v-model="keyword"
          type="text"
          placeholder="请输入职位标签"
          class="w400 marL10 bordeEd img marR10"
        ></el-input>
        <el-button
          class="bs_btn bs_text_oo el-button_border_ededed"
          icon="el-icon-plus"
          @click="inputContent"
        >添加</el-button>
        <div class="box marL10 marT20">
          <div class="top">
            <button
              class="bs_btn"
              v-for="(item,index) in label"
              :key="index"
              @click="buttonLabel(index,item)"
            >{{item}}</button>
          </div>
          <div class="bottom">
            <button
              class="bs_btn"
              v-for="(item,index) in label1"
              :key="index"
              @click="buttonLabel1(index,item)"
            >{{item}}</button>
          </div>
        </div>
        <!-- <div class="buttons">
          <el-button type="success" class="w350 bs_btn_oo" @click="fabuJob('form')">{{text2}}</el-button>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "../../../components/public/editor";
export default {
  components: {
    Editor
  },
  data() {
    return {
      label1: [
        "责任心",
        "执行力",
        "个人能力",
        "沟通协调能力",
        "五险一金",
        "双休"
      ],
      label: [],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      }
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    inputContent() {
      if (this.label.length >= 5) {
        this.$message.error("最多添加5个");
        return;
      }
      if (this.label.length < 5 && this.keyword != "") {
        this.label.push(this.keyword);
        this.keyword = "";
      }
    },
    buttonLabel(index, name) {
      this.label1.push(name);
      this.label.splice(index, 1);
    },
    buttonLabel1(index, name) {
      if (this.label.length >= 5) {
        this.$message.error("最多添加5个");
        return;
      }
      this.label.push(name);
      this.label1.splice(index, 1);
      let arr = this.label;
      for (let i = 0; i < arr.length; i++) {
        for (let k = i + 1; k < arr.length; k++) {
          if (arr[i] == arr[k]) {
            arr.splice(k, 1);
            k--;
          }
        }
      }
      this.label = arr;
    }
  }
};
</script>

<style lang="less" >
@import "~assets/css/enterprise/home/publish.less";
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.names {
  width: 305px;
}
.timing {
  width: 149px;
  margin-left: -10px;
}
.line {
  padding-right: 10px;
}

.w305 {
  width: 305px;
  height: 123px !important;
}
.el-form-item {
  margin-bottom: 13px;
}
.el-input__inner {
  height: 36px;
}
.checkbox {
  font-size: 14px;
  color: #999999;
  padding-left: 100px;
}
.facing {
  color: #333333;
  font-size: 14px;
}
.places {
  height: 52px;
}
</style>